<template>
  <div v-if="show" class="MobileReserveModels">
    <van-dialog v-model="show" :show-confirm-button="false">
      <template #title>
        <div>
          游戏预约
        </div>
      </template>
      <div class="form">
        <van-form ref="sendForm" validate-first @submit="submit" @failed="onFailed">
          <van-field
            v-model.trim="phone"
            name="phone"
            placeholder="手机号"
            :rules="[{ pattern: patternPhone, message: '请输入正确的手机号', trigger:'onBlur' }]"
          />
          <van-field
            v-model.trim="code"
            name="code"
            placeholder="验证码"
            :rules="[{ pattern: patternCode, message: '请输入正确的验证码',trigger:'onBlur'}]"
          >
            <template #button>
              <div v-if="sendAuthCode" class="getcode" @click="getCode"> {{ btn_text }}</div>
              <div v-else class="getcode">{{ time }}s后获取</div>
            </template>
          </van-field>
          <van-field
            v-model.trim="mail"
            name="mail"
            placeholder="邮箱"
            :rules="[{ pattern: patternMail, message: '请输入正确的邮箱',trigger:'onBlur'}]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">立即预约</van-button>
            <van-button native-type="button" style="margin-top: 16px;" round block type="default" @click.stop="closeReserve">取消</van-button>
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { reqSendCode, reqSubmit } from '@/api/home';
export default {
  data() {
    return {
      show: false,
      phone: '',
      code: '',
      mail: '',
      patternPhone: /^1\d{10}$/,
      patternCode: /^\d{6}$/,
      patternMail: /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/,
      time: '',
      sendAuthCode: true,
      btn_text: '获取验证码',
      timer: null
    }
  },
  methods: {
    open() {
      this.show = true
    },
    onFailed() {

    },
    async submit(data) {
      data.project = 'lostWord'
      data.os = 'android'
      const res = await reqSubmit(data)
      if (res.msg === 'error code') {
        this.$Toast.fail('验证码错误')
      }
      if (res.msg == 'repeat') {
        this.$Toast.fail('您已预约过啦！')
      }
      if (res.code === 200) {
        this.$Toast.success('预约成功')
        Object.assign(this.$data, this.$options.data())
        this.show = false
      }
    },
    async getCode() {
      try {
        await this.$refs['sendForm'].validate('phone')
      } catch (error) {
        console.log(error)
        if (error) return
      }
      const params = {
        phone: this.phone,
        os: 'android'
      }
      const res = await reqSendCode(params)
      if (res.msg == 'repeat') {
        return this.$Toast.fail('您已预约过啦！')
      }
      if (res.code === 200) {
        this.time = 60
        this.timer = setInterval(() => {
          --this.time
          this.sendAuthCode = false
          if (this.time <= 0) {
            clearInterval(this.timer)
            this.sendAuthCode = true
          }
        }, 1000)
      }
      if (res.msg === 'frequency is too high') {
        this.$Toast.fail('请勿频繁发送')
      }
    },
    closeReserve() {
      Object.assign(this.$data, this.$options.data())
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>

.MobileReserveModels {
  position: absolute;

}

.getcode {
  color: #3360ff;
}
</style>
